<template>
  <div id="online_courses_info_video" class="mycourses">
    <PageSubHeader :pageSubHeader="pageSubHeader" />

    <section class="course_title">
      <div class="w1400 bg-deco">
        <img class="bg-deco-img" src="@/statics/img/index/deco_heart_r.png" alt="">
      </div>
      <div class="w1100">
        <h3 class="title txt-bold">{{ video.name }}</h3>
        <div class="course_info">
          <p class="episode"><small>NO.{{ unitNum }}</small></p>
          <p class="course_name">{{ course.name }}</p>
          <p class="video_length">單元時長: {{ video.length }}</p>
          <p class="period">觀看/測驗期限: {{ computedExpiryTime }}</p>
        </div>
      </div>
    </section>

    <div class="course_description" v-if="video.content">
      <div class="w1100">
        <p class="txt-bold">單元說明</p>
        <p>{{ video.content }}</p>
      </div>
    </div>

    <div class="course_video">
      <div class="w1100 demo">
        <div
          class="video-wrapper"
          :style="{ maxHeight: videoWrapperMaxHeight }"
        >
          <resize-observer @notify="updateVideoWrapperMaxHeight" />
          <div class="video-container">
            <youtube
              ref="youtube"
              :video-id="youtubeID"
              :video-height="videoHeight"
              :video-width="videoWidth"
              :player-vars="playerVars"
              @ready="ready"
              @playing="playing"
              @paused="paused"
              @ended="enableExamBtn"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="timer txt-center mg-t-30">
      <p class="h3 txt-light_green" v-if="videoLength !== null">{{ hhmmss(videoLength) }}</p>
    </div>

    <div class="btn_wrap without_heart_btn">
      <div
        id="btn_wrapper"
        class="btn_wrapper"
        :class="{active: isVideoOver}"
      >
        <div class="cover"></div>
        <button type="button" id="button" class="btn" @click="goToTest">
          前往測驗題目
        </button>
        <!-- <router-link :to="`/register/online/mycourse/exam?
        course_id=${courseID}&unit=${unitNum}&video_id=${videoID}`" id="button" class="btn">
          前往測驗題目
        </router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import PageSubHeader from '@/components/PageSubHeader.vue';
import { RegisterOnlineSubHeader } from '@/lib/const';
import { coursesView, videoStatus, videoProgress } from '@/lib/http';
import { hhmmss } from '@/lib/public';
import { mapMutations } from 'vuex';

let timer;

export default {
  name: 'RegisterOnlineMycourseVideo',
  data() {
    return {
      ...RegisterOnlineSubHeader,
      courseID: '',
      videoID: '',
      unitNum: '',
      videoWrapperMaxHeight: '620px',
      videoId: 'QybtqmytCuE',
      videoHeight: '560',
      videoWidth: '315',
      playerVars: {
        controls: 0,
        fs: 0,
        modestbranding: 1,
        rel: 0,
        playsinline: 1,
        autoplay: 0,
        /** 開啟/關閉 鍵盤操作影片功能(快進、快退...等) */
        disablekb: 1,
        /** 可使用IFrame API來控制影片 */
        enablejsapi: 1,
        // start: 36,
      },
      videoLength: null,
      isVideoOver: false,
      /**
       * Axios Response Data
       */
      course: {},
      video: {},
    };
  },
  created() {
    this.courseID = this.$route.query.course_id;
    this.videoID = this.$route.query.video_id;
    this.unitNum = this.$route.query.unit;

    coursesView({
      uuid: this.courseID,
    }).then((result) => {
      this.course = result.data;

      /** 已購買 */
      if (this.course.bought_completed === false
        || this.course.bought_completed === true
      ) {
        /** 有限期 */
        if (this.course.expiry_time && this.course.expiry_time > 0) {
          /** 未過期 */
          if (moment(this.course.bought_expiry_time).isAfter()) {
            /**
             *  檢查影片觀看進度，若已看過則直接開啟測驗按鈕
             */
            videoProgress({
              video_uuid: this.videoID,
            }).then((res) => {
              // console.log('progress', res);
              if (res.data.result.completed * 1 === 2) {
                this.isVideoOver = true;
              }
            });
            // console.log('course', this.course);
            this.course.course_videos.forEach((video) => {
              if (video.uuid === this.videoID) {
                this.video = video;
              }
            });
            // console.log('video', this.video);
          /** 已過期 */
          } else {
            this.assignUrlRedirect('/register/online/index');
            this.updateModalInfoContent('課程已過期，請返回課程總覽');
            this.toggleModalInfo(true);
          }
        /** 無限期 */
        } else {
          /**
           *  檢查影片觀看進度，若已看過則直接開啟測驗按鈕
           */
          videoProgress({
            video_uuid: this.videoID,
          }).then((res) => {
            // console.log('progress', res);
            if (res.data.result.completed * 1 === 2) {
              this.isVideoOver = true;
            }
          });
          // console.log('course', this.course);
          this.course.course_videos.forEach((video) => {
            if (video.uuid === this.videoID) {
              this.video = video;
            }
          });
          // console.log('video', this.video);
        }
      /** 未登入、未購買 */
      } else {
        this.assignUrlRedirect('/register/online/index');
        this.updateModalInfoContent('您尚未登入或購買該課程，請返回課程總覽');
        this.toggleModalInfo(true);
      }
    });
  },
  destroyed() {
    clearInterval(timer);
  },
  computed: {
    computedExpiryTime() {
      /** 無期限 */
      if (!this.course.expiry_time) {
        return '無限制';
      }
      /** 課程已購買 */
      if (this.course.bought_completed === false || this.course.bought_completed === true) {
        return this.course.bought_expiry_time;
      }
      /** 課程未購買 */
      return `${this.course.expiry_days}天`;
    },
    youtubeID() {
      if (this.video.link) {
        return this.video.link.split('/').slice(-1).pop();
      }
      return null;
    },
  },
  methods: {
    ...mapMutations([
      'toggleModalInfo',
      'updateModalInfoContent',
      'assignUrlRedirect',
    ]),
    updateVideoWrapperMaxHeight() {
      const videoWrapper = document.querySelector('.video-wrapper');
      const newHeight = (parseFloat(getComputedStyle(videoWrapper, null).width.replace('px', '')) * 620) / 1100;
      this.videoWrapperMaxHeight = `${newHeight}px`;
    },
    ready() {
      // this.$refs.youtube.player.seekTo(3599, false);
      // this.$refs.youtube.player.cueVideoById({
      // videoId: this.videoID,
      // startSeconds: 3599,
      // });
    },
    playing() {
      /** 取得影片總時長(秒數)，並顯示於影片下方 */
      this.$refs.youtube.player.getDuration().then((success) => {
        const target = this;
        // console.log(success);
        if (this.videoLength === null) {
          this.videoLength = Math.floor(success);
        }
        timer = setInterval(() => {
          if (target.videoLength && target.videoLength * 1 > 0) {
            target.videoLength -= 1;
          }
          if (target.videoLength * 1 <= 0) {
            clearInterval(timer);
            target.videoLength = 0;
          }
          // console.log('remaining seconds', `${target.videoLength}s`);
        }, 1000);
      });
      videoStatus({
        video_uuid: this.videoID,
        type: 1,
      });
      // .then((result) => {
      //   console.log(result);
      // });
    },
    paused() {
      clearInterval(timer);
      let currentTime = null;
      this.$refs.youtube.player.getCurrentTime().then((success) => {
        currentTime = Math.floor(success);
        // console.log('proceeding seconds', `${currentTime}s`);
        videoStatus({
          video_uuid: this.videoID,
          type: 1,
          query_time: currentTime,
        });
        // .then((result) => {
        //   console.log(result);
        // });
      });
      // this.$refs.youtube.player.getDuration().then((success) => {
      //   console.log(success);
      // });
    },
    enableExamBtn() {
      videoStatus({
        video_uuid: this.videoID,
        type: 2,
      });
      // .then((result) => {
      //   console.log(result);
      // });
      this.isVideoOver = true;
    },
    goToTest() {
      if (this.isVideoOver) {
        this.$router.push({
          path: `/register/online/mycourse/exam?
          course_id=${this.courseID}&unit=${this.unitNum}&video_id=${this.videoID}`,
        });
      }
    },
    hhmmss,
  },
  components: {
    PageSubHeader,
  },
};
</script>
